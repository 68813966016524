import React, { useEffect } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import NestedContent from '../components/nested-content'
import { CookiesProvider } from 'react-cookie'
import Footer from '../components/footer'
import Header from '../components/header'
import Alert from '../components/utilities/Alert'
const PostPages = ({ pageContext }) => {
  const heroNestedContentItems = pageContext.post.heroStyle
  const nestedContentItems = pageContext.post.contentElements
  const files = pageContext.files
  const footer = pageContext.footer ? pageContext.footer : {}
  const header = pageContext.header ? pageContext.header : {}
  const seo = pageContext.seo
  const alert = pageContext.alert ? pageContext.alert : {}
  const sitemap = pageContext.sitemap ? pageContext.sitemap : {}
  const cookieConfig = {
    apiKey: '02a1522979eac771fd37934400992a0dc02c4371',
    product: 'PRO_MULTISITE',
    initialState: 'notify',
    setInnerHTML: 'true',
    layout: 'popup',
    text: {
      title: 'This site uses cookies.',
      intro:
        'We use cookies to (1) ensure that your use of our website is safe, (2) ensure that our website works property and (3) help us to understand how you use our website so that we can improve our website and improve the products and services we offer. This page explains the types of cookies we use in more detail and how you can opt in or out of some of them.',
      necessaryTitle: 'Essential Cookies',
      necessaryDescription:
        'Essential cookies are those that (1) ensure that your use of our website is safe, and (2) ensure that our website works property. As such you cannot opt out of these cookies if you wish to visit and use our website. By visiting our site, these cookies have already been placed. You can remove them by adjusting your web browser settings.'
    },
    rejectButton: true,
    necessaryCookies: ['cv-alert', 'siteVisits', 'siteCheck', 'alert'],
    optionalCookies: [
      {
        name: 'analytics cookies',
        label: 'Analytics Cookies',
        description:
          'Analytics cookies are not essential cookies (i.e. our website will function without them) but these cookies enable us to understand how you use our website so that we can improve our website and improve the products and services we offer. We use the following third party providers to achieve this. When you use our website they will place cookies and will provide us with some analysis of the cookie data they collect.<br><br><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">Google Analytics</a><br><br><a href="https://support.google.com/tagmanager/answer/6102821?hl=en">Google Tag Manager</a><br><br><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">Hotjar&nbsp;</a>',
        cookies: [
          '_ga',
          '_gid',
          '_gat',
          '__utma',
          '__utmt',
          '__utmb',
          '__utmc',
          '__utmz',
          '__utmv'
        ],
        onAccept: function () {
          ;(function (i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r
            ;(i[r] =
              i[r] ||
              function () {
                ;(i[r].q = i[r].q || []).push(arguments)
              }),
              (i[r].l = 1 * new Date())
            ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
            a.async = 1
            a.src = g
            m.parentNode.insertBefore(a, m)
          })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga')

          ga('create', 'UA-50812652-8', 'auto')
          ga('send', 'pageview')
        },
        onRevoke: function () {
          window['ga-disable-UA-50812652-8'] = true
        },
        recommendedState: true
      },
      {
        name: 'performance monitoring cookies',
        label: 'Performance Monitoring Cookies',
        description:
          'Performance monitoring cookies are not essential cookies (i.e. our website will function without them) but these cookies enable us to understand how well our site performs on different connection and platform types which will help us to improve the functionality of our website. They enable us to optimise the site for best performance. We use the following third party provider to achieve this. When you use our website they will place cookies and will provide us with some analysis of the cookie data they collect.<br><br><a href="https://www.pingdom.com/security/">Pingdom</a>',
        cookies: [],
        onAccept: function () {
          var _prum = [
            ['id', 'pa-621510fc0d7ce50011000868'],
            ['mark', 'firstbyte', new Date().getTime()]
          ]
          ;(function () {
            var s = document.getElementsByTagName('script')[0],
              p = document.createElement('script')
            p.async = 'async'
            p.src = '//rum-static.pingdom.net/pa-621510fc0d7ce50011000868.js'
            s.parentNode.insertBefore(p, s)
          })()
        },
        onRevoke: function () {},
        recommendedState: true
      }
    ],

    position: 'RIGHT',
    theme: 'DARK',
    branding: {
      fontColor: '#000',
      backgroundColor: '#febd11',
      fontSizeTitle: '1.2em',
      fontSizeIntro: '1em',
      fontSizeHeaders: '1.1em',
      fontSize: '1em',
      toggleText: '#40474f',
      toggleColor: '#40474f',
      toggleBackground: '#fefefe',
      buttonIcon: null,
      buttonIconWidth: '64px',
      buttonIconHeight: '64px',
      removeIcon: true,
      removeAbout: true
    }
  }
  const cookieOpener = () => {
    document.querySelectorAll('.optOutCookie').forEach((item) => {
      item.addEventListener('click', (event) => {
        event.preventDefault()
        CookieControl.open()
      })
    })
  }
  useEffect(() => {
    let counter = 0
    const cookieIsReady = () => {
      counter++
      if (typeof CookieControl === 'undefined') {
        if (counter < 100) {
          setTimeout(cookieIsReady, 100)
          return
        }
      } else {
        CookieControl.load(cookieConfig)
        cookieOpener()
      }
    }
    cookieIsReady()
  }, [])
  return (
    <>
      <CookiesProvider>
        <div>
          <div id="wrapper">
            <Header data={header} />
            <Layout>
              <Seo title={pageContext.post.metaTitle} name={pageContext.post.name} data={seo} />
              <NestedContent data={heroNestedContentItems} files={files} />
              <NestedContent data={nestedContentItems} files={files} sitemap={sitemap} />
            </Layout>
            <Footer data={footer} files={files} />
          </div>
        </div>
        <Alert data={alert} files={files} />
      </CookiesProvider>
    </>
  )
}

export default PostPages
